<template>
  <!-- 投诉建议 -->
  <div class="complaint">
    <div
      class="in-size"
      v-for="item in list"
      :key="item.key"
    >
      <div v-if="item.type == 'wbk'">
        <div class="topic">{{item.title}}</div>
        <div class="suggest">
          <van-field
            v-model="item.value"
            rows="5"
            autosize
            type="textarea"
            maxlength="200"
            placeholder="请输入建议"
            show-word-limit
            clearable
            contenteditable="true"
            style="border:2px solid #e2ebf0;border-radius:7px"
          />
        </div>
      </div>
    </div>
    <footer>
      <van-button
        v-if="Object.keys(list).length !== 0"
        class="button"
        type="primary"
        size="large"
        block
        @click="handleClick"
      >提交</van-button>
    </footer>
  </div>
</template>

<script>
import { submitPost, questionnaireQuery, feedback } from '@/network/service'
export default {
  name: 'complaint',
  data () {
    return {
      message: '',
      list: []
    }
  },
  mounted () {
    this.questionnaireQueryPost()
  },
  methods: {
    //请求投诉问卷
    async questionnaireQueryPost () {
      let moduleId = await questionnaireQuery()
      let result = moduleId.data.filter(el => el.id == "3")[0].templetId
      let res = await feedback({
        id: result
      })
      this.list = JSON.parse(res.data.content)
    },
    //提交建议
    async handleClick () {
      let len = this.list.filter(el => el.type == 'wbk')[0].value
      if (len.trim().length == 0) {
        this.list.filter(el => el.type == 'wbk')[0].value = ""
        this.$toast("不能为空")
      }
      else {
        await submitPost({
          commit_content: JSON.stringify({ pid: this.list.pid, sid: this.list.sid, value: this.list.value }),
          open_id: this.$store.state.cardList[0].openId,
          title: this.list.title
        })
        let data = this.$router.options.routes.find(item => item.redirect != undefined)
        this.$router.push(data.redirect)
        this.$toast("提交成功")
      }
    }
  },
}
</script>

<style lang="less" scoped>
.complaint {
  .topic {
    margin-top: 0.3rem;
    height: 2rem;
    line-height: 2rem;
    padding-left: 0.6rem;
  }
  .suggest {
    padding: 0.3rem;
  }
  .button {
    margin: auto;
    border-radius: 5rem;
    width: 90vw;
    margin-top: 2rem;
    background: @darkColor;
    border-color: @darkColor;
  }
  /deep/textarea {
    width: 94%;
  }
}
</style>